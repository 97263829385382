var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 活动审核 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-input',{attrs:{"placeholder":"请输入姓名","allowClear":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('a-input',{attrs:{"placeholder":"请输入手机号","allowClear":""},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('a-input',{attrs:{"placeholder":"请输入证件号","allowClear":""},model:{value:(_vm.idNumber),callback:function ($$v) {_vm.idNumber=$$v},expression:"idNumber"}}),_c('a-range-picker',{staticStyle:{"width":"350px"},attrs:{"format":"YYYY-MM-DD HH:mm:ss","placeholder":['提交开始时间','提交结束时间']},on:{"change":_vm.changeStartTime}}),_c('a-button',{staticClass:"btn",attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.search()}}},[_vm._v("搜索")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-tabs',{attrs:{"default-active-key":_vm.status},on:{"change":_vm.callback}},[_c('a-tab-pane',{key:"",attrs:{"tab":"全部"}}),_c('a-tab-pane',{key:"1",attrs:{"tab":"待审核"}}),_c('a-tab-pane',{key:"3",attrs:{"tab":"已通过"}})],1),_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.id; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"pagination":{
        total: _vm.total,
        current: _vm.pageNum,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNum - 1) * _vm.pageSize + i + 1)+" ")])]}},{key:"auditStatus",fn:function(text){return [_c('a-tag',{attrs:{"color":text == 1 ? 'orange' : text == 2 ? 'red' : text == 3 ? 'green' : ''}},[_vm._v(_vm._s(text == 1 ? '待审核' : text == 2 ? '已驳回' : text == 3 ? '已通过' : ''))])]}},{key:"operation",fn:function(text, record){return [_c('div',{staticClass:"blueText"},[_c('span',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['qualification:review:edit']),expression:"['qualification:review:edit']"}],on:{"click":function($event){return _vm.toDetail(record.id, 2)}}},[_vm._v(_vm._s(record.status == 1 ? '审核' : '详情'))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }