<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 活动审核 </template>
      <template #input>
        <a-input placeholder="请输入姓名" v-model="name" allowClear/>
        <a-input placeholder="请输入手机号" v-model="mobile" allowClear/>
        <a-input placeholder="请输入证件号" v-model="idNumber" allowClear/>
        <a-range-picker
          @change="changeStartTime"
          format="YYYY-MM-DD HH:mm:ss"
          :placeholder="['提交开始时间','提交结束时间']"
          style="width: 350px"
        />
        <!-- <a-select v-model="operationName" placeholder="请选择活动名称" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="大学生专属优惠">大学生专属优惠</a-select-option>
        </a-select> -->
        <a-button type="primary" class="btn" :loading="loading" @click="search()"
          >搜索</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs :default-active-key="status" @change="callback">
        <a-tab-pane key="" tab="全部"></a-tab-pane>
        <a-tab-pane key="1" tab="待审核"></a-tab-pane>
        <a-tab-pane key="3" tab="已通过"></a-tab-pane>
      </a-tabs>
      <a-table
        class="table-template"
        :rowKey="(item) => item.id"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNum - 1) * pageSize + i + 1 }}
          </div>
        </template>

        <!-- 审核状态 -->
        <template slot="auditStatus" slot-scope="text">
          <a-tag :color="text == 1 ? 'orange' : text == 2 ? 'red' : text == 3 ? 'green' : ''">{{text == 1 ? '待审核' : text == 2 ? '已驳回' : text == 3 ? '已通过' : ''}}</a-tag>
        </template>

        <template slot="operation" slot-scope="text, record">
          <div class="blueText">
            <span v-hasPermi="['qualification:review:edit']" @click="toDetail(record.id, 2)">{{record.status == 1 ? '审核' : '详情'}}</span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "证件号码",
    align: "center",
    dataIndex: "idNo",
  },
  {
    title: "提交时间",
    align: "center",
    dataIndex: "applyTime",
  },
  {
    title: "活动名称",
    align: "center",
    dataIndex: "operationName",
  },
  {
    title: "审核人",
    align: "center",
    dataIndex: "reviewerName",
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: { customRender: "auditStatus" },
  },
  {
    title: "操作",
    align: "center",
    width: "220px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNum: 1, //页码
      pageSize: 10, // 页数

      status: "", 
      name: "",
      mobile: "",
      idNumber: "",
      start:"",
      end:"",
      operationName: undefined, 
    };
  },
  // 事件处理器
  methods: {
    callback(e){
      this.pageNum = 1;
      this.status = e
      this.getdataList();
    },
    changeStartTime(x, s) {
      this.start = s[0];
      this.end = s[1];
    },
    toDetail(id) {
      this.$router.push("/admin/AuditManage/StudentDiscountsDetail?" + "id=" + id);
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.getdataList();
    },
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/qualification/list",
        method: "get",
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          status: this.status, // 审核状态 0 无，1 待审核 2驳回3 通过
          name: this.name,
          mobile: this.mobile,
          idNumber: this.idNumber,
          start: this.start,
          end: this.end,
          operationName: this.operationName,
        },
      }).then((res) => {
        this.loading = false
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getdataList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.ant-tabs-bar{
  border-bottom: none !important;
}
.table-template{
  margin-top: 0;
}
</style>
